import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import { SEO, Layout, DefaultHeader, Footer, PostPreview } from "components";
import { SearchIcon } from "assets/svgs";
import styled from "styled-components";
import { Arrow } from "assets/svgs";
import { Button } from "../mixins";

export const query = graphql`
  query NewsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    banner: file(relativePath: { eq: "assets/images/news-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posts: allSanityArticle(
      limit: 8
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
          newsCategories {
            title
            id
          }
        }
      }
    }
    newsCategories: allSanityNewsCategory {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const NewsPage = (props) => {
  // Get Site Data and News Posts / Categories
  const { data } = props;

  const site = (data || {}).site;
  const banner = (data || {}).banner;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
  const newsCategories = (data || {}).newsCategories ? mapEdgesToNodes(data.newsCategories) : [];

  // Search News Posts
  const [searchQuery, setSearchQuery] = useState("");
  const searchData = postNodes.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery);
  });

  const handleSearchChange = (e) => {
    setCategory("all");
    setSearchQuery(e.target.value.toLowerCase());
    setPage([0, 6]);
  };

  // Category Selector

  const [category, setCategory] = useState("all");
  const selectedCategory = postNodes.reduce(function (filtered, item) {
    item.newsCategories.map((itemCategory) => {
      if (category === "all") {
        filtered.push(item);
      } else if (itemCategory.title.toLowerCase() === category.toLowerCase()) {
        filtered.push(item);
      }
    });
    return filtered;
  }, []);

  // Pagination

  const [[pageStart, pageEnd], setPage] = useState([0, 6]);
  const itemCount = 6;

  const getPageNumbers = (results) => {
    const filtered = [];
    while (results.length) {
      filtered.push(results.splice(0, itemCount));
    }
    return (
      <>
        <Left onClick={() => prevPage()} disabled={pageStart === 0} />

        {filtered.map((filter, i) => {
          if (filtered.length >= 2) {
            console.log(filtered.length);
            return (
              <PageButton
                key={i}
                onClick={() => {
                  setPage([i * 6, i * 6 + 6]);
                }}
                disabled={pageStart === i * 6}
              >
                {i + 1}
              </PageButton>
            );
          }
        })}
        <Right
          onClick={() => nextPage(filtered.length * 6)}
          disabled={pageEnd >= filtered.length * 6}
        />
      </>
    );
  };

  const nextPage = (results) => {
    if (pageEnd < results) setPage([pageStart + 6, pageEnd + 6]);
  };

  const prevPage = () => {
    if (pageStart >= 6) setPage([pageStart - 6, pageEnd - 6]);
  };

  const handleCategoryChange = (e) => {
    e.preventDefault();
    setSearchQuery("");
    setCategory(e.target.value.toLowerCase());
    setPage([0, 6]);
  };

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title="News"
        description="Check in with LHS here to get our latest company news, blogs and relevant industry content."
      />
      <DefaultHeader
        title="LHS News"
        description="Check in with LHS here to get our latest company news, blogs and relevant industry content."
        imageFluid={banner}
      />
      <Container>
        <SearchBarLabel>Search By Keywords</SearchBarLabel>
        <SearchBar>
          <SearchIcon />
          <input
            onChange={handleSearchChange}
            value={searchQuery}
            type="text"
            name="searchbar"
            placeholder="Enter Keywords"
          />
        </SearchBar>
        <CategoriesLabel>Search By Category</CategoriesLabel>

        <Categories>
          <Category
            value="all"
            type="submit"
            name="all"
            onClick={(e) => {
              handleCategoryChange(e);
            }}
          >
            Show All
          </Category>
          {newsCategories.map((node) => (
            <Category
              key={node.id}
              value={node.title}
              type="submit"
              name={node.title}
              onClick={(e) => {
                handleCategoryChange(e);
              }}
            >
              {node.title}
            </Category>
          ))}
        </Categories>
        <PostGrid>
          {searchQuery !== "" &&
            searchData.length >= 1 &&
            searchData
              .map((node) => <PostPreview key={node.id} node={node} />)
              .slice(pageStart, pageEnd)}

          {((searchQuery !== "" && !searchData.length) || !selectedCategory.length) && (
            <NoResults>No matching search results</NoResults>
          )}
          {searchQuery === "" &&
            selectedCategory
              .map((node) => <PostPreview key={node.id} node={node} />)
              .slice(pageStart, pageEnd)}
        </PostGrid>
        <Paginate>
          {searchQuery !== "" && searchData.length >= 1 && <>{getPageNumbers(searchData)}</>}
          {searchQuery === "" && <>{getPageNumbers(selectedCategory)}</>}
        </Paginate>
      </Container>
      <Footer />
    </Layout>
  );
};

const PageButton = styled(Button)`
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  color: ${({ disabled }) => (disabled ? "white" : "#f94c00")};
  background: ${({ disabled }) => (disabled ? "#f94c00" : "transparent")};
  border-radius: 6px;
  border: none;
  outline: none;
  margin: 0 0.25rem;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #f94c00;
  &:hover {
    background: ${({ disabled }) => (disabled ? "#f94c00" : "#EAF6FF")};
    color: ${({ disabled }) => (disabled ? "white" : "#f94c00")};
  }
`;

const Left = styled(Arrow)`
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  border: ${({ disabled }) => (disabled ? "1px solid #959C9B" : "1px solid #f94c00")};
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-radius: 6px;
  padding: 0.6rem;
  transition: 0.3s;
  margin-right: 0.5rem;
  path {
    stroke-width: 3px;
    stroke: ${({ disabled }) => (disabled ? "#959C9B" : "#f94c00")};
  }
  &:hover {
    background: ${({ disabled }) => (disabled ? "transparent" : "#f94c00")};
    path {
      stroke: ${({ disabled }) => (disabled ? "#959C9B" : "white")};
    }
  }
`;

const Right = styled(Arrow)`
  width: 2rem;
  height: 2rem;
  transform: rotate(-90deg);
  border: ${({ disabled }) => (disabled ? "1px solid #959C9B" : "1px solid #f94c00")};
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-radius: 6px;
  padding: 0.6rem;
  transition: 0.3s;
  margin-left: 0.5rem;
  path {
    stroke-width: 3px;
    stroke: ${({ disabled }) => (disabled ? "#959C9B" : "#f94c00")};
  }
  &:hover {
    background: ${({ disabled }) => (disabled ? "transparent" : "#f94c00")};
    path {
      stroke: ${({ disabled }) => (disabled ? "#959C9B" : "white")};
    }
  }
`;

const Paginate = styled.div`
  position: relative;
  height: 3rem;
  grid-column: 1/7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  > div {
    position: relative;
    width: auto;
  }
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Category = styled.button`
  width: auto;
  min-width: 100px;
  height: auto;
  padding: 0.75rem;
  margin-bottom: 0.5em;
  background: transparent;
  color: #f94c00;
  margin-right: 1rem;
  border: none;
  outline: none;
  font-size: 0.875rem;
  border-radius: 0.5em;
  transition: 0.5s;
  border: 1px solid #f94c00;
  cursor: pointer;
  &:hover {
    background: #f94c00;
    color: white;
  }
  &:focus {
    background: #f94c00;
    color: white;
  }
`;

const CategoriesLabel = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.25em;
  color: #243f93;
  grid-column: 1/7;
  padding: 0 1rem;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Categories = styled.div`
  grid-column: 1/7;
  margin-bottom: 2rem;
  padding: 0 1rem;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const NoResults = styled.div`
  width: 100%;
  padding: 0.75rem 0.4rem;
  text-decoration: none;
  background: transparent;
  color: #ff7979;
  font-size: 1rem !important;
  font-weight: 300;
  margin: 0.25rem 0;
  border-radius: 3px;
  transition: 0.5s;
  grid-column: 1/7;
  @media (min-width: 1150px) {
    grid-column: 1/7;
  }
`;

const SearchBarLabel = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.25em;
  color: #243f93;
  grid-column: 1/7;
  padding: 0 1rem;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const SearchBar = styled.div`
  max-width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid #232528;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  grid-column: 1/7;
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    path {
      stroke: #959c9b;
    }
  }
  input {
    border: none;
    outline: none;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.5rem;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: #959c9b;
    }
  }
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Post = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  padding-bottom: 3rem;
  transition: 0.5s;
  text-decoration: none;
  &:after {
    opacity: 0;
    content: "Show More";
    font-weight: 600;
    color: #f94c00;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1rem;
    transition: 0.5s;
  }

  &:hover {
    background: #eaf6ff;
    &:after {
      opacity: 1;
    }
  }
`;

const PostGrid = styled.section`
  grid-column: 1/7;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
  @media (min-width: 768px) {
    grid-column: 2/6;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 0;
  grid-gap: 0.625rem;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    margin-top: 2rem;
  }
`;

export default NewsPage;
